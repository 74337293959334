import React, { useEffect } from "react";
import PageHeader from "../../components/newest/PageHeader";
import Layout from "../../components/common/Layout";
import Fade from "react-reveal/Fade";
import Gallery from "../../components/newest/Gallery";

const Newest = ({ data }) => {
  const artworks = data.artworks.nodes;
  return (
    <Layout
      meta={{
        description:
          "Museum-quality posters made on thick matte paper. Add a wonderful accent to your room and office with these posters that are sure to brighten any environment.",
        title: "Prints | LWE Art",
        type: "website",
      }}
      title={"Prints | LWE Art"}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader title="Prints" />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <Gallery artworks={artworks} />
        </Fade>
      </main>
    </Layout>
  );
};

export default Newest;

export const pageQuery = graphql`
  query {
    artworks: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fileAbsolutePath: { regex: "/artworks/" }
        frontmatter: { type: { eq: "print" } }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          type
          images
          soldOut
          prices
          discount
          meta {
            title
            description
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;
